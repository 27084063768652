import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Top from './pages/Top'
import Trademark from './pages/Trademark'
import PlanStepSelector from './pages/PlanStepSelector'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/detail" component={Top} />
        <Route exact path="/trademark" component={Trademark} />
        <Route exact path="/" component={PlanStepSelector} />
      </Switch> 
    </Router>
  );
}

export default App;
