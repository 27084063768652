import React, { useContext } from 'react'
import { Store } from '../../store/index'
import { fade, makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      marginRight: theme.spacing(2),
      marginTop: 10,
    },
    item: {
        backgroundColor: fade(theme.palette.common.white, 0.3),
        '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.3),
        },
    },
    group: {
        fontSize: 22,
        marginTop: 20,
        marginBottom: 10,
    },
    chiplist: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        backgroundColor: fade(theme.palette.common.white, 0.4),
        margin: theme.spacing(0.5),
    },
  }));

const FilterItemTags = () => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    var headerString = ""

    function deleteTag(deleteFilterItem) {
        const newFilterItemTag = globalState.terms.filter((item) => item !== deleteFilterItem)
        const changeToggle = "SGT_" + deleteFilterItem

        setGlobalState({type: 'SET_TERMS', payload: {terms: newFilterItemTag }})

        if(document.getElementById(changeToggle.replace(/\s+/g, "")) != null){
            document.getElementById(changeToggle.replace(/\s+/g, "")).click()
        }
    }

    if(globalState.terms.length === 0) headerString = ''
    if(globalState.terms.length >= 1) headerString = 'filter tags'

    return (
        <div className={classes.root}>
            <h2 className={classes.group}>{headerString}</h2>
            <li className={classes.chiplist}>
            {
                globalState.terms && globalState.terms.map((filterItem) => {
                    return (
                            <Chip
                                key={filterItem}
                                label={filterItem}
                                onDelete={() => deleteTag(filterItem)}
                                className={classes.chip}
                            />
                    )
                })
            }
            </li>
        </div>
    )
}

export default FilterItemTags
