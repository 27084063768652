import React, { useContext } from 'react'
import { Store } from '../../store/index'
import { fade, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: 300,
        marginTop: 10,
        marginRight: 30,
        paddingBottom: 10,
        backgroundColor: fade(theme.palette.common.white, 0.8),
        boxShadow: '0 10px 25px 0 rgba(0, 0, 50, 0.3)',
    },
    title: {
        marginTop: 6,
        marginBottom: 6,
    },
    detail: {
        marginTop: 6,
    },
}));

//
// globalState.compare holds array value
// return array value
//
const toArray = (value) => {
    const arr = []

    arr.push(value)
    return arr
}

const GridItem = ({id, planname, detail, cost, tax, xsSize}) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)

    return (
        <Grid item xs={xsSize}>
            <Card id={id} className={classes.root}>
                <CardContent>
                    <Typography className={classes.title} variant="h6">
                        {planname}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {cost} 円({tax} / 一人当たり月額)
                    </Typography>
                    <Typography className={classes.detail} variant="body2">
                        {detail}
                    </Typography>
                </CardContent>
                <CardActions>
                    <div onClick={() => setGlobalState({type: 'SET_COMPARE', payload: {compare: toArray(id) }})}>
                        <Button size="small">More</Button>
                    </div>
                    <div onClick={() => {
                        if(globalState.compare.indexOf(id) < 0){
                            globalState.compare.push(id)
                            setGlobalState({type: 'SET_COMPARE', payload: {compare: globalState.compare }})
                        }
                    }}><Button size="small">Compare</Button></div>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default GridItem
