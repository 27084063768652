import React, { useRef, useEffect, useContext } from 'react'
import Layout from '../components/Layout/Layout'
import TopMoreFunction from '../components/TopMoreFunction/TopMoreFunction'
import TopPlanLists from '../components/TopPlanLists/TopPlanLists'
import { fetchPlansData } from '../apis/index'
import { Store } from '../store/index'
import { fade,makeStyles } from '@material-ui/core/styles'
import FilterItem from '../components/FilterItem/FilterItem'
import FilterItemTags from '../components/FilterItemTags/FilterItemTags'
import CircularIndeterminate from '../components/atoms/CircularIndeterminate'
import FilterItemSwitch from '../components/FilterItemSwitch/FilterItemSwitch'
import GoogleAds from 'react-google-ads'
import TopModal from '../components/modal/TopModal'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
    main: {
        marginLeft: 30,
        height: '100%',
    },
    circularWrap: {
        height: '100vh',
        width: '5%',
        paddingTop: '40vh',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    ads: {
        marginLeft: 30,
        marginTop: '100px',
        height: '150px',
    },
    disclaimer:{
        margin: '20px 30px 20px 30px', 
        backgroundColor: fade(theme.palette.common.white, 0.8),
    },
    detail:{
        textAlign: 'center',
        paddingTop: '20',
        paddingBottom: '20',
    },
    description: {
        marginTop: '20px',
        marginBottom: '20px',
        marginRight: '40px',
        fontSize: '60%',
        clear: 'both',
    },
    AlertDiv: {
        backgroundColor: fade(theme.palette.common.white, 0.8),
    }
  }));

const Top = () => {
    const mounted = useRef(true)
    // eslint-disable-next-line
    const { globalState, setGlobalState } = useContext(Store)
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        if(mounted.current){
            //初回のみ実行
            
            //start Circular
            mounted.current = false
            setGlobalState({type: 'SET_LOAD', payload: {load: true}})

            //get data
            fetchPlansData().then((res) => {
                // eslint-disable-next-line
                setGlobalState({type: 'SET_PLANS', payload: {plans: res.data.plans}})
                // finish data load 
                setGlobalState({type: 'SET_LOAD', payload: {load: false}})

            })
        }else{
            //その他
        }
     // eslint-disable-next-line
    },[])

    return (
        <Layout>
            <TopModal />
            {
                globalState.load && globalState.load == true &&                  
                    <div className={classes.circularWrap}><CircularIndeterminate /></div>
            }
            <div className={classes.main}>
                <div className={classes.description}>
                    <Collapse in={open}>
                    <Alert className={classes.AlertDiv}
                        severity="info"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                    }
                    >
                        このサイトではMicrosoft 365のプラン選択をより簡単に行える用、タグでプランを絞り込む事ができます。
                    </Alert>
                </Collapse>

                </div>
                <h2>Plan Filter</h2>
                <FilterItem />
                <FilterItemSwitch />
                <FilterItemTags />
                {/* plan lists area */}
                <TopPlanLists />
                {/* detail,compare area */}
                {
                    globalState.compare && globalState.compare.length > 0 &&
                        <TopMoreFunction />  
                }
            </div>
            {/* google adsense */}
            <div className={classes.ads}>
                <GoogleAds
                    className="adsbygoogle"
                    client="ca-pub-2097363869430669"
                    slot="9523242397"
                    format="auto"
                    //style={{ display: 'inline-block', width: '100%', }}
                />
            </div>
            <Box>
                <Card id="TopDisclaimer" className={classes.disclaimer}>
                    <CardContent>
                        <Typography className={classes.detail} variant="body2">
                            当サイトのご利用にあたっては、メニュー内リンクより「サイトのご利用について」をご覧ください。<br />
                            Microsoft 365の正確な情報については、<Link href="https://www.microsoft.com/ja-jp/microsoft-365" color="primary" underline="none" target="_blank" rel="noopener noreferrer">公式Webサイト</Link>より内容をご確認の上、ご利用下さい。
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Layout>
    )
}

export default Top
