import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
      marginTop: '100px',
      width: '100%',
      marginleft: '100px',
    },
  },
}));


const CircularIndeterminate = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.circle}>
                <CircularProgress color="secondary" />
            </div>
        </div>
    );
}

export default CircularIndeterminate
