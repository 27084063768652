import React, { useContext } from 'react'
import { Store } from '../../store/index'
import { fade, makeStyles } from '@material-ui/core/styles';
import { includeServiceCheck } from '../../DataManipulation/includeServiceCheck'

//for Table
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
    comparetable: {
        marginTop: 10,
        width: "98%",
        backgroundColor: fade(theme.palette.common.white, 0.8),
        boxShadow: '0 10px 25px 0 rgba(0, 0, 50, 0.3)',

    },
    group: {
        fontSize: 22,
        marginTop: 30,
        marginBottom: 0,
    },
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
    smallcomments: {
    },

}));

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const keyLv1 = 'plan'
    const keyLv2 = ['ClientApp','BusinessApp','CollaborateApp','DataServices','SecurityApp']

    return (
       <React.Fragment>
             <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.plan.name}
                </TableCell>
                <TableCell align="right">{row.plan.cost_year} 円</TableCell>
                {
                    keyLv2.map((lv2) => {
                        return <TableCell key={lv2} align="right">{includeServiceCheck(row,keyLv1,lv2).length}</TableCell>
                    })
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1} marginLeft={6}>
                    <Typography variant="h6" gutterBottom component="div">
                        includes
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                        <TableRow>
                            <TableCell key="detail-table-category">Category</TableCell>
                            <TableCell key="detail-table-servicename">ServiceName</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            keyLv2.map((lv2,index) => {
                                var includeService = []
                                
                                includeService = includeServiceCheck(row,keyLv1,lv2)
    
                                return (
                                    <TableRow key={index}>
                                        <TableCell key={lv2} component="th" scope="row">{lv2}</TableCell>
                                        <TableCell key={index + lv2}>
                                        {
                                            includeService.map((service) => {
                                                if(service.include === "true"){
                                                    return (service.name + ", ")
                                                }else{
                                                    return (service.name + " " + service.include + ", ")
                                                }
                                            })
                                        }
                                        </TableCell>
                                    </TableRow>
                                )    
                            })
                        }
                        </TableBody>
                    </Table>
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
  }
  

const TopMoreFunction = () => {
    // eslint-disable-next-line
    const { globalState, setGlobalState } = useContext(Store)
    const classes = useStyles();
    var headerString = ""

    if(globalState.compare.length === 1) headerString = 'detail'
    if(globalState.compare.length > 1) headerString = 'compare'

    return (
        <div>
            <h2 className={classes.group}>{headerString}</h2>
            <TableContainer component={Paper} className={classes.comparetable}>
                <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>plan name</TableCell>
                        <TableCell align="right">cost&nbsp;(*1)</TableCell>
                        <TableCell align="right">ClientApp</TableCell>
                        <TableCell align="right">BusinessApp</TableCell>
                        <TableCell align="right">CollaborateApp</TableCell>
                        <TableCell align="right">DataServices</TableCell>
                        <TableCell align="right">SecurityApp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        globalState.compare && globalState.compare.map((filter) => {
                            return globalState.plans && globalState.plans.map((data) => {
                                if(data.id === filter){
                                    return (
                                        <Row
                                            key={data.id}
                                            row={data}
                                        />                                    
                                    )
                                }else{
                                    return (
                                        null
                                    )
                                }
                            })
                            
                        })
                    }
                </TableBody>
                </Table>
                <p><small>(*1) 1ライセンス(1人)あたりの月額費用, 税抜, 年間契約時</small></p>
            </TableContainer>
        </div>
    )
}

export default TopMoreFunction
