import React, { useContext, useState, useEffect } from 'react'
import GridWrapper from '../GridWrapper/GridWrapper'
import GridItem from '../GridItem/GridItem'
import { Store } from '../../store/index'
import { fade, makeStyles } from '@material-ui/core/styles';
import { keywordExistCheck } from '../../DataManipulation/keywordExistCheck'

const useStyles = makeStyles((theme) => ({
    group: {
        fontSize: 22,
        marginTop: 40,
        marginBottom: 0,
    },
  }));

export const useWindowDimensions = () => {
 
    const getWindowDimensions = () => {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }
   
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      const onResize = () => {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, []);
    return windowDimensions;
}

const TopPlanLists = () => {
    const classes = useStyles();
     // eslint-disable-next-line
    const { globalState, setGlobalState } = useContext(Store)

    //use Responsive Grid Layout
    const { width, height } = useWindowDimensions();
    let xsSize = 12 / Math.floor(width / 350)
    
    if(xsSize > 12) xsSize = 12
    if(xsSize < 1) xsSize = 1

    return (
        <div>
            <h2 className={classes.group}>Plans</h2>
            <GridWrapper>
                {
                    globalState.plans && globalState.plans.map((data) => {
                        if(keywordExistCheck(data,globalState.terms)){
                            return(
                                <GridItem
                                    id={data.id}
                                    key={data.id}
                                    cost={data.plan.cost_year}
                                    tax={data.plan.tax}
                                    planname={data.plan.name}
                                    detail={data.plan.comment}
                                    xsSize={xsSize}
                                />
                            )
                        }else{
                            return(
                                <span key={data.id}></span>
                            )    
                        }
                    })
                }
            </GridWrapper>          
        </div>
    )
}

export default TopPlanLists
