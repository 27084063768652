export const includeServiceCheck = (jsondata,keyLv1,keyLv2) => {
    let conditionMatch = []
    
    //Check true item of keyLv2 items
    jsondata[keyLv1][keyLv2].filter((item) => {
        if (item.include !== "false"){
            conditionMatch.push(item)
            return true
        }else{
            return false
        }
    })

    return conditionMatch
}
