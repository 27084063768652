export const keywordExistCheck = (jsondata,keywords) => {
    let existFlag = -1
    let chkplan = ""
    let conditionMatch = []
    const keyLv1 = 'plan'
    const keyLv2 = ['license','BusinessApp','ClientApp','CollaborateApp','DataServices','SecurityApp','Other']
    const keyLv2_License = 'max_license'

    //no filter keywords
    if(keywords.length === 0) return true

    //Check true item of keyLv2 items -> conditionMatch
    for (const key of keyLv2){
        jsondata[keyLv1][key].filter((item) => {
            if (item.include !== "false"){
                conditionMatch.push(item)
                return true
            }else{
                return false
            }
        })
    }

    //check apps in conditionMatch
    chkplan = JSON.stringify(conditionMatch).toUpperCase()
    keywords.map((keyword) => {
        if (chkplan.indexOf(keyword) >= 0){
            if (existFlag === -1){
                existFlag = 1               
            }
            return true 
        }else{
            existFlag = 0
            return false
        }
    })

    return existFlag === 1 ? true : false
}
