import React from 'react'
import Link from '@material-ui/core/Link'
import { fade, makeStyles } from '@material-ui/core/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: "100%",
        marginTop: '20px',
        padding: '10px 0',
        textAlign: 'center',
        backgroundColor: fade('#c2c2c2', 0.2),
        position: 'fixed',
        bottom: '0',
        minHeight: '30px',
    },
    footerWrapper: {
        minHeight: "60px",
    },
    icon: {
        marginLeft:"20px",
    },
  }));



const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.footerWrapper}>
            <div className={classes.footer}>
                <Link href="https://twitter.com/qoobook" color="inherit" underline="none" target="_blank" rel="noopener noreferrer"><TwitterIcon className={classes.icon} color="primary" style={{ fontSize: 20 }} /></Link>
                <Link href="https://github.com/hashihei/" color="inherit" underline="none" target="_blank" rel="noopener noreferrer"><GitHubIcon className={classes.icon}  style={{ fontSize: 20 }} /></Link>
            </div>
        </div>
    )
}

export default Footer
