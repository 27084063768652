import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    wrapper: {
        minheight: '100vh',
    },
    main: {
        marginLeft: 30,
        height: '100%',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
    },
  }));

const Layout = ({children}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout