import React, { useContext,useEffect } from 'react'
import { Store } from '../../store/index'
import Switch from '@material-ui/core/Switch';
import { fade, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    SwitchGroup: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#ffffff', 1.0),
      marginTop: '20px',
      padding: '10px',
      marginBottom: '20px',
      marginRight: '30px',
      fontSize: '60%',
      clear: 'both',
      maxWidth: '95%',
    },
    SwitchGroupItem: {
        display: "inline-block",
        marginRight: '10px',
    },
    SwitchGroupToggle: {
        fontSize: 22,
        fontWeight: 700,
    }
  }));



const FilterItemSwitch = () => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        OfficeDesktop: false,
        OfficeOnline: false,
        Apps: false,
        Exchange: false,
        OneDrive: false,
        Sharepoint: false,
        Teams: false,
        TeamsWithICT: false,
        PowerBI: false,
        PowerApps: false,
        Planner: false,
        Forms: false,
        Intune: false,
        AAD: false,
        License300: false,
        MoreLicense: false,
      });

    const { globalState, setGlobalState } = useContext(Store)

    useEffect(() => {
        //setTerm(globalState.term)
        // eslint-disable-next-line
      },[])

    const ToggleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });   

    }
    const handleChange = (event) => {
        let editTagName = []

        setState({ ...state, [event.target.name]: event.target.checked });

        switch(event.target.name){
            case 'OfficeDesktop':
                editTagName = ["WORD","EXCEL","POWERPOINT","OUTLOOK","ONENOTE","ACCESS"]
                break;
            case 'OfficeOnline':
                editTagName = ["OFFICE ONLINE"]
                break;
            case 'Exchange':
                editTagName = ["EXCHANGE"]
                break;
            case 'OneDrive':
                editTagName = ["ONEDRIVE"]
                break;
            case 'Sharepoint':
                editTagName = ["SHAREPOINT"]
                break;
            case 'Teams':
                editTagName = ["TEAMS"]
                break;
            case 'TeamsWithICT':
                editTagName = ["電話"]
                break;
            case 'PowerBI':
                editTagName = ["POWER BI"]
                break;
            case 'PowerApps':
                editTagName = ["POWERAPPS"]
                break;
            case 'Planner':
                editTagName = ["PLANNER"]
                break;
            case 'Forms':
                editTagName = ["FORMS"]
                break;
            case 'Intune':
                editTagName = ["INTUNE"]
                break;
            case 'AAD':
                editTagName = ["AZURE ACTIVE DIRECTORY"]
                break;
            case 'MoreLicense':
                editTagName = ["MORELICENSE"]
                break;
            case 'License300':
                editTagName = ["300LICENSE"]
                break;
            default :
        }    

        if (event.target.checked){
            //check on,
            editTagName.forEach(tag => {
                if (globalState.terms.indexOf(tag) === -1){
                    globalState.terms.push(tag)
                    setGlobalState({type: 'SET_TERMS', payload: { terms: globalState.terms }})
                }                    
            });
        }else {
            //check off,
            let newFilterItemTag = globalState.terms
            editTagName.forEach(tag => {
                if (globalState.terms.indexOf(tag) > -1){
                    newFilterItemTag = newFilterItemTag.filter((item) => item !== tag)
                }
            });
            setGlobalState({type: 'SET_TERMS', payload: {terms: newFilterItemTag }})
        }
    };

    return (
        <div className={classes.SwitchGroup}>
            <div className={classes.SwitchGroupToggle}>Select Apps<Switch
                    checked={state.Apps}
                    onChange={ToggleChange}
                    name="Apps"
                    id="SGT_APPS"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
            /></div>
            { state.Apps && 
            <div id='ServiceFilterToggle'>
                <div className={classes.SwitchGroupItem}>Office Online<Switch
                    checked={state.OfficeOnline}
                    onChange={handleChange}
                    name="OfficeOnline"
                    id="SGT_OFFICEONLINE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Office (Desktop App)<Switch
                    checked={state.OfficeDesktop}
                    onChange={handleChange}
                    name="OfficeDesktop"
                    id="SGT_OFFICEDESKTOP"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>メール(Exchange)<Switch
                    checked={state.Exchange}
                    onChange={handleChange}
                    name="Exchange"
                    id="SGT_EXCHANGE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>クラウドストレージ(OneDrive)<Switch
                    checked={state.OneDrive}
                    onChange={handleChange}
                    name="OneDrive"
                    id="SGT_ONEDRIVE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>コラボレーションツール(SharePoint)<Switch
                    checked={state.Sharepoint}
                    onChange={handleChange}
                    name="Sharepoint"
                    id="SGT_SHAREPOINT"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>コミュニケーションツール(Teams))<Switch
                    checked={state.Teams}
                    onChange={handleChange}
                    name="Teams"
                    id="SGT_TEAMS"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Teamsの電話網との統合<Switch
                    checked={state.TeamsWithICT}
                    onChange={handleChange}
                    name="TeamsWithICT"
                    id="SGT_電話"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Power BI<Switch
                    checked={state.PowerBI}
                    onChange={handleChange}
                    name="PowerBI"
                    id="SGT_POWERBI"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Power Apps for M365<Switch
                    checked={state.PowerApps}
                    onChange={handleChange}
                    name="PowerApps"
                    id="SGT_POWERAPPS"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Planner<Switch
                    checked={state.Planner}
                    onChange={handleChange}
                    name="Planner"
                    id="SGT_PLANNER"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>Forms<Switch
                    checked={state.Forms}
                    onChange={handleChange}
                    name="Forms"
                    id="SGT_FORMS"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>MDM(Intune)<Switch
                    checked={state.Intune}
                    onChange={handleChange}
                    name="Intune"
                    id="SGT_INTUNE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>ID管理(AzureActiveDirectory P1/P2)<Switch
                    checked={state.AAD}
                    onChange={handleChange}
                    name="AAD"
                    id="SGT_AZUREACTIVEDIRECTORY"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>中小規模向け(～300ライセンス)<Switch
                    checked={state.License300}
                    onChange={handleChange}
                    name="License300"
                    id="SGT_300LICENSE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>
                <div className={classes.SwitchGroupItem}>大規模向け(ライセンス数制限無し)<Switch
                    checked={state.MoreLicense}
                    onChange={handleChange}
                    name="MoreLicense"
                    id="SGT_MORELICENSE"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>

            </div>
            }
        </div>
    )
}

export default FilterItemSwitch
