import React from 'react'
import Layout from '../components/Layout/Layout'
import { fade, makeStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import GoogleAds from 'react-google-ads'


const useStyles = makeStyles((theme) => ({
    main: {
        marginLeft: 30,
        height: '100vh',
        width: '90%',
    },
    contents: {
        backgroundColor: fade(theme.palette.common.white, 0.8),
    },
    title: {
        fontSize: "15px",
    },
    mainBox:{
        marginBottom: "60px",
    },
    ads: {
        marginTop: '100px',
    },
  }));

const Trademark = () => {
    const classes = useStyles();

    return (
        <Layout>
            <div className={classes.main}>
                <h2>免責事項</h2>
                <Box className={classes.mainBox}>
                    <Card id="trademarkcard" className={classes.contents}>
                        <CardContent>
                            <Typography className={classes.detail} variant="body2">
                                <ul>
                                    <li>当サイトへ掲載された情報の内容の正確性については一切保証しません。</li>
                                    <li>当サイトに掲載された情報・資料を閲覧、使用、ダウンロードするなどの行為に関連して生じたあらゆる損害等についても、理由の如何に関わらず、一切責任を負いません。</li>
                                    <li>当サイトに掲載している情報には、第三者が提供している情報が含まれていますが、その内容の正確性については一切責任を負いかねますのでご了承ください。</li>
                                </ul>
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                <h2>商標または登録商標</h2>
                <p>当Webサイトに記載されている商品またはサービスなどの名称は、各社の商標または登録商標です。</p>
                <Box className={classes.mainBox}>
                    <Card id="trademarkcard" className={classes.contents}>
                        <CardContent>
                            <Typography className={classes.detail} variant="body2">
                                <ul>
                                    <li>Windows, Microsoft 365, Azure, Azue Active Directory, Office, Word, Excel, Power Point, Teams, Outlookを含む、
                                        Microsoft 365 Plan Candidateに記載する各種サービス名称は、Microsoft Corporationの米国及びその他の国における商標または登録商標です。
                                    </li>
                                </ul>
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </div>
            {/* google adsense */}
            <div className={classes.ads}>
                <GoogleAds
                    className="adsbygoogle"
                    client="ca-pub-2097363869430669"
                    slot="9523242397"
                    format="auto"
                    //style={{ display: 'inline-block', width: '100%', }}
                />
            </div>            
        </Layout>
    )
}

export default Trademark
