import React, { useState, useEffect, useContext } from 'react'
import { Store } from '../../store/index'
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#ffffff', 0.8),
      '&:hover': {
        backgroundColor: fade('#ffffff', 1.0),
      },
      marginRight: theme.spacing(2),
      marginTop: 10,
      width: '80%',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  }));

const FilterItem = () => {
    const classes = useStyles();
    const [term , setTerm] = useState('')
    const { globalState, setGlobalState } = useContext(Store)

    const setInputKeyword = e => {
        e.preventDefault()

        if (document.getElementById('inputFilter').value !== ""){
            document.getElementById('inputFilter').value = ""
            if (globalState.terms.indexOf(term) === -1){
                globalState.terms.push(term.toUpperCase())  //大文字にして格納
                setGlobalState({type: 'SET_TERMS', payload: { terms: globalState.terms }})
                let switchId = "SGT_" + term.toUpperCase()
                if(document.getElementById(switchId.replace(/\s+/g, "")) != null){
                  document.getElementById(switchId.replace(/\s+/g, "")).click()
                }
            }
        }
    }

    useEffect(() => {
      //setTerm(globalState.term)
      // eslint-disable-next-line
    },[])

    return (
        <div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={setInputKeyword}>
                <InputBase
                    id='inputFilter'
                    placeholder="Filter plans by tag or keywords."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    autoComplete='off'
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={ e => setTerm(e.target.value)} 
                    fullWidth={true}     
                />
            </form>
          </div>        
        </div>
    )
}

export default FilterItem
