import React from 'react'
import Link from '@material-ui/core/Link'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#eceff1",
      position: "fixed",
      width: "100%",
      top: "0",
      zIndex: "2000",
    },
    Wrapper: {
      minHeight: "60px",
      position: "relative",
      zIndex: "2000",
    },
    WrapperBackTop: {
      minHeight: "60px",
      zIndex: "1",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuPaper: {
      backgroundColor: fade("#eceff1", 0.95),
      marginTop: "35px",
    },
    title: {
      flexGrow: 1,
      marginLeft: '10px',
      marginRight: '10px',
    },
    pageLink: {
      paddingLeft: '20px',
    }
  }));

const Header = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className={classes.Wrapper}>
      <div className={classes.root}>
        <AppBar position="static" color="transparent">
          <Toolbar variant="dense">
            <MenuIcon aria-haspopup="true" onClick={handleClick}>
              <IconButton edge="start" className={classes.menuButton} color="transparent" aria-label="menu" />
            </MenuIcon>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              TransitionComponent={Fade}
              classes={{ paper: classes.menuPaper }}
            >
              <MenuItem onClick={handleClose}><Link href="/trademark" color="inherit" underline="none" ><b>サイトのご利用について</b></Link></MenuItem>
              <MenuItem onClick={handleClose}><Link href="https://blog.hashihei.com" color="inherit" underline="none" target="_blank" rel="noopener noreferrer"><b>blog</b></Link></MenuItem>
              <MenuItem onClick={handleClose}><Link href="/" color="inherit" underline="none" ><b>top</b></Link></MenuItem>
            </Menu>

            <Typography className={classes.title} variant="h6" color="inherit">
              Microsoft 365 Plan Candidate
            </Typography>

            <div>
                <Link className={classes.pageLink} href="/"  color="inherit" underline="none" ><Button>ざっくり</Button></Link>
                <Link className={classes.pageLink} href="/detail"  color="inherit" underline="none" ><Button>こまかく</Button></Link>
            </div>

          </Toolbar>
        </AppBar>
      </div>
      </div>
    );
}

export default Header
