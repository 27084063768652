import React, { useEffect } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import GoogleAds from 'react-google-ads'
import Button from '@material-ui/core/Button';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}
  
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: fade(theme.palette.common.white, 0.9),
      borderRadius: '5px',
      textAlign: 'center'
    },
    adsDisplayType:{
        display: 'block',
    },
}));

const TopModal = () => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);
    const [firstpaint, setFirstpaint] = React.useState(true);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        setFirstpaint(false)
    }, [])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <GoogleAds
                className="adsbygoogle"
                client="ca-pub-2097363869430669"
                slot="9523242397"
                format="auto"
                style={{ display: 'inline-block', width: '100%', }}
            />
            <Button type="button" onClick={handleClose}>
                Close
            </Button>
        </div>
    );
  
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
    );
}

export default TopModal
