import React, { useRef, useEffect, useContext } from 'react'
import { Store } from '../store/index'
import Layout from '../components/Layout/Layout'
import TopMoreFunction from '../components/TopMoreFunction/TopMoreFunction'
import TopPlanLists from '../components/TopPlanLists/TopPlanLists'
import { fetchPlansData } from '../apis/index'
import { fade,makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GoogleAds from 'react-google-ads'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ExpandMore, ExpandLess, Refresh } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    main: {
        marginLeft: 30,
        height: '100%',
        marginTop: '20px',
        marginBottom: '20px',
        marginRight: '40px',
        fontSize: '60%',
        clear: 'both',
    },
    
    mainStepper: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.2),
    },
    button: {
        marginTop: 10,
        marginRight: 20,
    },

    h2t: {
        marginLeft: 30,        
    },

    resetContainer: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.2),
        paddingLeft: 30,
        paddingBottom: 30,
    },

    resetContainerbutton: {
        marginTop: 20,
        fontWeight: 800,
    },

  }));


function getSteps() {
    return ['License', 'Office Desktop Application', 'Mail', 'Communication App', 'MDM & MAM', 'IDaaS'];
}

function getStepContent(step) {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const [state, setState] = React.useState({
        OfficeDesktop: 'false',
        OfficeOnline: 'false',
        Apps: 'false',
        Exchange: 'false',
        OneDrive: 'false',
        Sharepoint: 'false',
        Teams: 'false',
        TeamsWithICT: 'false',
        PowerBI: 'false',
        PowerApps: 'false',
        Planner: 'false',
        Forms: 'false',
        Intune: 'false',
        AAD: 'false',
        License300: 'false',
        MoreLicense: 'false',
    });

    const handleChange = (event) => {
        let editTagName = []

        setState({ ...state, [event.target.name]: event.target.value });

        switch(event.target.name){
            case 'OfficeDesktop':
                editTagName = ["WORD","EXCEL","POWERPOINT","OUTLOOK","ONENOTE","ACCESS"]
                break;
            case 'OfficeOnline':
                editTagName = ["OFFICE ONLINE"]
                break;
            case 'Exchange':
                editTagName = ["EXCHANGE"]
                break;
            case 'OneDrive':
                editTagName = ["ONEDRIVE"]
                break;
            case 'Sharepoint':
                editTagName = ["SHAREPOINT"]
                break;
            case 'Teams':
                editTagName = ["TEAMS"]
                break;
            case 'TeamsWithICT':
                editTagName = ["電話"]
                break;
            case 'PowerBI':
                editTagName = ["POWER BI"]
                break;
            case 'PowerApps':
                editTagName = ["POWERAPPS"]
                break;
            case 'Planner':
                editTagName = ["PLANNER"]
                break;
            case 'Forms':
                editTagName = ["FORMS"]
                break;
            case 'Intune':
                editTagName = ["INTUNE"]
                break;
            case 'AAD':
                editTagName = ["AZURE ACTIVE DIRECTORY"]
                break;
            case 'MoreLicense':
                editTagName = ["MORELICENSE"]
                break;
            default :
        }    

        if (event.target.value == 'true'){
            //check on,
            editTagName.forEach(tag => {
                if (globalState.terms.indexOf(tag) === -1){
                    globalState.terms.push(tag)
                    setGlobalState({type: 'SET_TERMS', payload: { terms: globalState.terms }})
                }                    
            });
        }else {
            //check off,
            let newFilterItemTag = globalState.terms
            editTagName.forEach(tag => {
                if (globalState.terms.indexOf(tag) > -1){
                    newFilterItemTag = newFilterItemTag.filter((item) => item !== tag)
                }
            });
            setGlobalState({type: 'SET_TERMS', payload: {terms: newFilterItemTag }})
        }
    };

    switch (step) {
        case 0:
            return (
                <div>
                    <div>該当するプランを利用する人数は300人以上ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="MoreLicense" name="MoreLicense" value={state.MoreLicense} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        case 1:
            return (
                <div>
                    <div>Office アプリはWeb版だけでなく、デスクトップアプリも必要ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="OfficeDesktop" name="OfficeDesktop" value={state.OfficeDesktop} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        case 2:
            return (
                <div>
                    <div>メールサービスは必要ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="Exchange" name="Exchange" value={state.Exchange} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        case 3:
            return (
                <div>
                    <div>Web会議やチャットツールは必要ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="Teams" name="Teams" value={state.Teams} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        case 4:
            return (
                <div>
                    <div>デバイス管理やアプリケーション配布の機能は必要ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="Intune" name="Intune" value={state.Intune} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );

        case 5:
            return (
                <div>
                    <div>リソースへのアクセスを保護する為に条件付きアクセス(ゼロトラスト)は必要ですか？</div>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="AAD" name="AAD" value={state.AAD} onChange={handleChange}>
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
            );

        default:
        return 'Unknown step';
    }
}


const PlanStepSelector = () => {
    const mounted = useRef(true)
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const { globalState, setGlobalState } = useContext(Store)

    useEffect(() => {
        if(mounted.current){
            //初回のみ実行
            
            //start Circular
            mounted.current = false
            setGlobalState({type: 'SET_LOAD', payload: {load: true}})

            //get data
            fetchPlansData().then((res) => {
                // eslint-disable-next-line
                setGlobalState({type: 'SET_PLANS', payload: {plans: res.data.plans}})
                // finish data load 
                setGlobalState({type: 'SET_LOAD', payload: {load: false}})

            })
        }else{
            //その他
        }
     // eslint-disable-next-line
      },[])

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    return (
        <Layout>
            <h2 className={classes.h2t}>簡易プラン選定</h2>
            <p　className={classes.h2t}>6つの質問に答えて頂ければ候補プランを提示します。</p>
            <div className={classes.main}>
                <Stepper className={classes.mainStepper} activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                            <Button
                                disabled={activeStep === 0}
                                variant="text"
                                onClick={handleBack}
                                className={classes.button}
                                startIcon={<ExpandLess />}
                            >
                                Back
                            </Button>
                            <Button
                                variant="text"
                                onClick={handleNext}
                                className={classes.button}
                                startIcon={<ExpandMore />}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                            </div>
                        </div>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>これで全て完了です。以下のプランがおすすめです。より詳細な確認が必要な場合は、TOPページからご確認下さい。</Typography>
                        <Button variant="text" startIcon={<Refresh />} onClick={handleReset} className={classes.resetContainerbutton}>
                            やり直す
                        </Button>
                    </Paper>
                )}

                {/* plan lists area */}
                <TopPlanLists />
                {/* detail,compare area */}
                {
                    globalState.compare && globalState.compare.length > 0 &&
                        <TopMoreFunction />  
                }

            </div>
            {/* google adsense */}
            <div className={classes.ads}>
                <GoogleAds
                    className="adsbygoogle"
                    client="ca-pub-2097363869430669"
                    slot="9523242397"
                    format="auto"
                    //style={{ display: 'inline-block', width: '100%', }}
                />
            </div>            
        </Layout>
    )
}

export default PlanStepSelector
